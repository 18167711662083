<script setup lang="ts">
    import type { HeroData } from '~/@types/cms';
    import type { ButtonProps, ButtonStyling } from '~/components/atm/Button.vue';

    interface HeroProps {
        heroData: HeroData;
        fullHeight?: boolean;
        bottomMargin?: boolean;
        isFirstHero?: boolean;
        isSlider?: boolean;
    }

    const props = withDefaults(defineProps<HeroProps>(), {
        fullHeight: false,
        bottomMargin: false,
    });

    // Constants
    const black = woomColors.black.DEFAULT as string;
    const white = woomColors.white.DEFAULT as string;
    const red = woomColors.red.DEFAULT as string;

    const isMobile = ref(false);
    useMediaQuery([
        {
            query: defaultQueries.max.mobile,
            ref: isMobile,
        },
    ]);

    const {
        font_color = ref(undefined),
        label_color = ref(undefined),
        button_color = ref(undefined),
        halign = ref('left'),
        valign = ref('bottom'),
        logo: logoStyle = ref(undefined),
        overlay: overlayOpacity = ref('opacity_0'),
    } = toRefs(reactive(props.heroData.style || ({} as Exclude<HeroData['style'], undefined>)));

    const logoSizeMapMobile: Record<string, string> = {
        '128': 'w-32',
        '176': 'w-44',
        '240': 'w-60',
    };
    const logoSizeMapTabletUp: Record<string, string> = {
        '240': 'md:w-60',
        '360': 'md:w-[360px]',
        '450': 'md:w-[450px]',
    };
    const logoWidthMobile = computed(() => {
        if (logoStyle.value === 'small') return '128';
        if (logoStyle.value === 'large') return '240';
        return '176'; // Default to medium
    });
    const logoWidthTabletUp = computed(() => {
        if (logoStyle.value === 'small') return '240';
        if (logoStyle.value === 'large') return '450';
        return '360'; // Default to medium
    });

    const theme = computed(() => props.heroData.style?.theme || 'light');

    const fontStyles = computed(() => {
        if (theme.value !== 'custom') undefined;
        return {
            color: font_color.value || white,
        };
    });

    const buttons = computed<{
        mainStyling: ButtonStyling;
        secondaryStyling: ButtonStyling;
        colorBg: ButtonProps['colorBg'];
        colorLabel: ButtonProps['colorLabel'];
    }>(() => {
        if (theme.value === 'dark')
            return {
                mainStyling: 'solid',
                secondaryStyling: 'outline',
                colorBg: black,
                colorLabel: black,
            };
        if (theme.value === 'custom')
            return {
                mainStyling: 'solid-custom',
                secondaryStyling: 'outline-custom',
                colorBg: button_color.value || red,
                colorLabel: label_color.value || black,
            };
        return {
            mainStyling: 'solid-invert',
            secondaryStyling: 'outline-invert',
            colorBg: red,
            colorLabel: white,
        };
    });
    const useSizeProp = computed(() => !props.fullHeight && !props.isSlider);
    const heroSize = computed(() => props.heroData.style?.size || 'medium');
    const isAutoHeight = computed(() => useSizeProp.value && heroSize.value === 'auto_height');

    const isAnimation = computed(() => Boolean(props.heroData.animation?.url));
</script>
<template>
    <section
        :id="heroData.codename"
        :class="[
            'mol-hero relative flex',
            {
                'min-h-screen-mobile lg:min-h-screen-desktop': fullHeight,
                'md:min-h-[75vh]': useSizeProp && heroSize !== 'auto_height',
                'min-h-[50vh]': useSizeProp && heroSize === 'small',
                'min-h-[75vh]': useSizeProp && heroSize === 'medium',
            },
        ]">
        <lazy-atm-link
            v-if="heroData.link"
            :link="heroData.link"
            link-only
            class="absolute inset-0 z-10 h-full w-full"
            aria-hidden="true"
            tabindex="-1" />
        <atm-asset
            :data="heroData.heroMobile || heroData.hero"
            :image-preload="isFirstHero"
            :class="[
                'lb-hero-image h-full w-full md:hidden',
                {
                    absolute: !isAutoHeight,
                },
            ]"
            cover
            :image-loading="isFirstHero ? 'eager' : 'lazy'"
            sizes="300 xs:640 sm:768"
            preset="2to3"
            role="presentation" />
        <atm-asset
            :data="heroData.hero"
            :class="[
                'lb-hero-image hidden h-full w-full md:block',
                {
                    absolute: !isAutoHeight,
                },
            ]"
            cover
            :image-loading="isFirstHero ? 'eager' : 'lazy'"
            role="presentation"
            sizes="1px md:1264px lg:1920px" />
        <div :class="['absolute inset-0 bg-black', overlayOpacity?.replace('_', '-')]"></div>
        <atm-grid
            v-if="!isAutoHeight"
            class="pointer-events-none relative z-20 w-full">
            <div
                :class="[
                    'col-span-2 flex w-full md:col-span-12',
                    {
                        'lg:col-span-8 lg:col-start-3': isAnimation,
                        'justify-start text-left': halign === 'left',
                        'hero-justify-center justify-center text-center': halign === 'center',
                        'hero-justify-right justify-end text-right': halign === 'right',
                        'items-start': valign === 'top',
                        'items-center': valign === 'middle',
                        'items-end': valign === 'bottom',
                    },
                ]">
                <div
                    :class="[
                        'lb-hero-content relative my-6 flex w-full flex-col gap-4 max-md:w-full md:my-8 lg:px-0',
                        {
                            'lg:max-w-lg': !isAnimation,
                            'mb-12 md:mb-16': bottomMargin,
                            'text-woom-black': theme === 'dark',
                            'text-woom-white': theme === 'light',
                        },
                    ]"
                    :style="fontStyles">
                    <lazy-atm-animation
                        v-if="heroData.animation?.url"
                        :url="heroData.animation.url"
                        class="w-full" />
                    <template v-else>
                        <div
                            v-if="heroData.logo"
                            :class="`${logoSizeMapMobile[logoWidthMobile]} ${logoSizeMapTabletUp[logoWidthTabletUp]}`">
                            <lazy-atm-image
                                :data="heroData.logo"
                                :sizes="`${logoWidthMobile} md:${logoWidthTabletUp}`"
                                class="w-full"
                                role="presentation" />
                        </div>
                        <lazy-atm-heading
                            v-if="heroData.title"
                            :level="isSlider || !isFirstHero ? 'h2' : 'h1'">
                            <renderer-html :content="woomTextFormat(heroData.title)" />
                        </lazy-atm-heading>

                        <div
                            v-if="heroData.subtitle"
                            class="text-lg max-md:invisible max-md:hidden">
                            <renderer-html :content="woomTextFormat(heroData.subtitle)" />
                        </div>

                        <template v-if="heroData.link || heroData.link2">
                            <div class="flex gap-2 max-md:flex-col">
                                <lazy-atm-link
                                    v-if="heroData.link"
                                    :link="heroData.link"
                                    :styling="buttons.mainStyling"
                                    :color-bg="buttons.colorBg"
                                    :color-label="buttons.colorLabel"
                                    class="pointer-events-auto max-md:max-w-none" />
                                <lazy-atm-link
                                    v-if="heroData.link2"
                                    :link="heroData.link2"
                                    :styling="buttons.secondaryStyling"
                                    :color-bg="buttons.colorBg"
                                    :color-label="buttons.colorLabel"
                                    class="pointer-events-auto max-md:max-w-none" />
                            </div>
                        </template>
                    </template>
                </div>
            </div>
        </atm-grid>
    </section>
</template>
<style scoped>
    .lb-hero-image {
        transform: translateY(var(--translateY));
        @apply will-change-transform;
    }

    .lb-hero-content {
        transform: translateY(var(--translate-logo));
        @apply will-change-transform;
    }

    .hero-justify-center > div > * {
        @apply ml-auto mr-auto;
    }

    .hero-justify-right > div > * {
        @apply ml-auto;
    }
</style>
