<script setup lang="ts">
    import type { BackgroundColorsPredefined } from '~/@types/cms';

    export interface CarouselSlideProps {
        slide: CMSProduct;
        textColor: string;
        backgroundColor: BackgroundColorsPredefined;
        isActive: boolean;
        isDuplicate?: boolean;
    }

    const props = defineProps<CarouselSlideProps>();

    const { isUS } = useLocales();

    const {
        config,
        variant,
        colorOption,
        colorOptionChanged,
        changeHoverColorOption,
        currentImage,
        handleAddToCart,
        cartBusy,
        addToCartClicked,
        toURL,
        addToCartDisabled,
        isRedCharity,
    } = useProductCard(props.slide);

    const isDarkBackground = computed(() => {
        return props.backgroundColor === 'black';
    });
</script>
<template>
    <div
        :class="['org-carousel-slide-product m-auto flex h-full flex-col', { 'bg-[#FF0000]': isDuplicate }]"
        :style="{
            '--color-option': colorOption ? '2rem' : '0px',
            '--dropdown-option': config?.options?.length ? '4rem' : '0px',
            '--bike-detail': slide.bikeDetail ? '4rem' : '0px',
            '--stamped-stars': isUS ? '33px' : '0px',
        }">
        <div :class="['flex h-full shrink items-center justify-center', { 'image-container': isActive }]">
            <div class="relative flex h-full items-center overflow-hidden">
                <nuxt-link
                    :class="['h-full max-h-full', { 'pointer-events-none': !isActive }]"
                    :to="toURL"
                    link-only>
                    <lazy-atm-image
                        v-if="currentImage"
                        class="aspect-3/2 max-h-full"
                        provider="assets"
                        :sizes="nuxtImageSizes.carousel"
                        :src="currentImage"
                        :alt="removeWoomTextFormat(slide.formattedTitle)"
                        cover
                /></nuxt-link>
                <client-only>
                    <lazy-mol-product-labels
                        v-if="slide.labels && variant"
                        :labels="slide.labels"
                        :variant-labels="variant.labels"
                        :class="['top-0', { hidden: !isActive }]" />
                </client-only>
                <lazy-woom-icon-red-charity
                    v-if="isRedCharity"
                    class="absolute right-0 top-0 h-8 fill-woom-red max-md:h-6" />
            </div>
        </div>

        <div
            :class="[
                'flex shrink-0 flex-col gap-2 text-center',
                { 'product-info': isActive, 'opacity-0': !isActive },
                `color-swatch-bg-${backgroundColor}`,
            ]">
            <div
                v-if="slide.formattedTitle || slide.title"
                :class="{ textColor, 'font-bold': !config?.isBike }">
                <renderer-html :content="woomTextFormat(slide.formattedTitle || slide.title)" />
            </div>

            <atm-stamped-stars
                :to="toURL"
                :data-id="slide.productId" />

            <lazy-atm-price
                v-if="slide.price && !config?.isBike"
                :price="config?.selected.value?.price"
                :compare-at-price="config?.selected.value?.compareAtPrice"
                alignment="center" />

            <mol-product-card-options
                :config="config"
                :change-hover-color-option="changeHoverColorOption"
                :color-option-changed="colorOptionChanged"
                color-option-classes="mx-auto mb-1"
                other-option-classes="mx-auto mt-4 w-full md:w-2/3 md:max-w-80" />

            <span
                v-if="slide.bikeDetail"
                :class="['font-momo flex flex-col content-center justify-items-center text-xs', textColor]">
                <lazy-atm-product-card-bike-details :bike-detail="slide.bikeDetail" />
                <lazy-atm-price
                    v-if="slide.price"
                    :price="config?.selected.value?.price"
                    :compare-at-price="config?.selected.value?.compareAtPrice"
                    alignment="center" />
            </span>
            <mol-product-card-buttons
                class="mx-auto max-w-sm justify-center"
                :isDarkBackground="isDarkBackground"
                :addToCartDisabled="addToCartDisabled"
                :product="slide"
                :variant="variant"
                :cartBusy="cartBusy"
                :addToCartClicked="addToCartClicked"
                :handleAddToCart="() => handleAddToCart('product_carousel')"
                :pdpUrl="config?.variantUrl.value" />
        </div>
    </div>
</template>
<style scoped>
    .org-carousel-slide-product {
        --product-info: calc(10rem + var(--color-option) + var(--dropdown-option) + var(--bike-detail) + var(--stamped-stars));
    }
    @media screen(md) {
        .image-container {
            max-height: calc(100% - var(--product-info));
        }
        .product-info {
            height: var(--product-info);
        }
    }
</style>
